import axios from "axios";

const apiClient = () => {
  const requestHeaders = { "Content-Type": "application/json" };
  const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_URL}`,
    responseType: "json",
    headers: requestHeaders,
    timeout: 100000,
  });
  return axiosInstance;
};

export default apiClient;
