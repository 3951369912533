import "./App.css";
import { useNavigate, useParams } from "react-router-dom";
import MakeCallForm from "./components/MakeCallForm";
import SmallHeader from "./components/SmallHeader";

function App() {
  const params = useParams();

  return (
    <>
      <SmallHeader />
      <div className="flex py-4 px-8">
        {/* <div
          className="ml-auto text-blue-600 cursor-pointer hover:underline"
          onClick={() => {
            navigate("/logs");
          }}>
          {" "}
          Logs
        </div> */}
      </div>

      <div className="flex flex-col items-center justify-start min-h-screen px-4 lg:p-24 space-y-2 bg-zinc-50">
        <h1 className="text-2xl font-bold mb-4 text-slate-700">Demo Call</h1>

        <MakeCallForm company_id={params.company_id} />
      </div>
    </>
  );
}

export default App;
